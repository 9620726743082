<p-overlayPanel #op [style]="{ width: '300px' }">
  <ng-template pTemplate="content">
    <app-layout-manager (closing)="op.toggle({})"></app-layout-manager>
  </ng-template>
</p-overlayPanel>

<div class="flex flex-column gap-3 h-full">
  <div class="flex flex-row gap-2 align-items-center">
    <span class="font-medium text-lg"> Layouts: </span>
    <p-button
      size="small"
      icon="fa-solid fa-caret-down fa-right"
      iconPos="right"
      [label]="currentLayoutName()"
      (click)="op.toggle($event)" />

    @if(!isAddingNewLayout()) {
    <p-button size="small" label="Update" icon="fa-solid fa-floppy-disk" [outlined]="true" (click)="updateLayout()" />
    <p-button size="small" label="Add" icon="fa-solid fa-plus" [text]="true" (click)="isAddingNewLayout.set(true)" />
    } @else {
    <input type="text" pInputText placeholder="Enter New Layout Name" [formControl]="newLayoutName" />
    <p-button size="small" icon="fa-solid fa-check" [text]="true" (click)="saveNewLayout()" />
    <p-button size="small" icon="fa-solid fa-times" [text]="true" (click)="isAddingNewLayout.set(false)" />
    }

    <p-button
      size="small"
      class="ml-auto"
      label="Chart"
      icon="fa-duotone fa-solid fa-chart-pie"
      [outlined]="true"
      (onClick)="isChartShowing = true"></p-button>

    <p-button size="small" label="Export" icon="fa-duotone fa-solid fa-file-excel" (onClick)="exportGrid()" />
  </div>

  <div class="flex-1">
    <adaptable-provider
      class="h-full"
      [adaptableOptions]="adaptableOptions"
      [gridOptions]="gridOptions"
      [modules]="agGridModules"
      (adaptableReady)="adaptableReady($event)">
      <adaptable-ui class="d-none"></adaptable-ui>
      <ag-grid-angular
        *adaptable="let adaptable"
        [gridOptions]="adaptable.gridOptions"
        [modules]="adaptable.modules"
        [rowData]="rowData()"
        class="ag-theme-quartz h-full"
        style="min-height: 600px">
      </ag-grid-angular>
    </adaptable-provider>
  </div>
</div>

<p-dialog
  header="Global Visibility Chart"
  [modal]="true"
  [dismissableMask]="true"
  [(visible)]="isChartShowing"
  [style]="{ width: '45rem' }">
  <div class="flex flex-column align-items-center mb-5">
    <div style="color: red; font-size: 8pt; width: 100%">Note:  Princeton Pharmacy Expiration Costs will not be available until Expiration Dates are captured.</div>
    <aspire-drill-down-bar-chart [rawData]="rowData()" [colorMap]="ExpirationRiskColorMap"></aspire-drill-down-bar-chart>
  </div>
</p-dialog>
