import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewChild, computed, inject, signal } from '@angular/core';
import { CommonModule, NgOptimizedImage } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { NavigationStart, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { DomSanitizer, Title } from '@angular/platform-browser';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { AvatarModule } from 'primeng/avatar';
import { ButtonModule } from 'primeng/button';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DropdownModule } from 'primeng/dropdown';
import { MenubarModule } from 'primeng/menubar';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';
import { Sidebar, SidebarModule } from 'primeng/sidebar';
import { ToastModule } from 'primeng/toast';

import { Subject } from 'rxjs';

//import { MSAL_GUARD_CONFIG, MsalBroadcastService, MsalGuardConfiguration, MsalService } from '@azure/msal-angular';
//import {
//  AccountInfo,
//  AuthenticationResult,
//  EventMessage,
//  EventType,
//  InteractionStatus,
//  PopupRequest,
//  RedirectRequest
//} from '@azure/msal-browser';

import { environment } from '@env/environment'

import { DividerModule } from 'primeng/divider';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BlankTenant, tenantPaths } from '@const/tenants';
import { TenantHelperService } from '@services/tenant-helper/tenant-helper.service';
import { AuthHelperService } from '@services/auth-helper/auth-helper.service';

@Component({
  selector: 'app-master-page',
  standalone: true,
  imports: [
    AvatarModule,
    ConfirmDialogModule,
    CommonModule,
    MenuModule,
    DropdownModule,
    FontAwesomeModule,
    MenubarModule,
    MenuModule,
    RouterOutlet,
    RouterLink,
    RouterLinkActive,
    NgOptimizedImage,
    ToastModule,
    ButtonModule,
    SidebarModule,
    DividerModule
  ],
  templateUrl: './master-page.component.html',
  styleUrl: './master-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterPageComponent implements OnInit, OnDestroy {
  @ViewChild('sidebarRef') sidebarRef!: Sidebar;

  /** Local flag for 'is prod'/'is dev' so we can show this on the UI */
  isProd = environment.production;

  private router = inject(Router);

  private tenantService = inject(TenantHelperService);

  private sanitizer = inject(DomSanitizer);

  private http = inject(HttpClient);

  private userService = inject(AuthHelperService);

  title = inject(Title);

  /** The root URL for the API */
  apiUrl = this.sanitizer.bypassSecurityTrustResourceUrl(environment.baseApiUrl);

  //private msalGuardConfig = inject<MsalGuardConfiguration>(MSAL_GUARD_CONFIG);

  //private authService = inject(MsalService);

  //private msalBroadcastService = inject(MsalBroadcastService);

  private readonly _destroying$ = new Subject<void>();

  /** The currently active/signed in account of the user who is accessing this application */
  //activeAccount = signal<AccountInfo | null>(null);

  /** Profile image of the signed in user. It will be blank if no users is signed in. */
  profileImage = signal('');

  /**
   * Menu items to display for the user when they ARE logged in.
   * This is NOT a signal because these are hard coded!!!
   */
  loggedInItems: MenuItem[] = [];

  /**
   * Menu items to display for the user when they are NOT logged in.
   * This is NOT a signal because these are hard coded!!!
   */
  loggedOutItems: MenuItem[] = [];

  /**
   * The items that show up in the profile dropdown.
   * This is NOT a signal because these are currently calculated in the constructor
   */
  userMenuItems: MenuItem[] = [];

  /** The list of actions/items that the currently logged in user can access */
  appMenuItems = signal<MenuItem[]>([]);

  isCollapsed = signal<boolean>(false);

  /**
   * The items that show up in the menu sidebar
   * This is NOT a signal because this is hard coded in the constructor!!!
   */
  menuItems = signal<MenuItem[]>([]);

  currMenuItem = signal<MenuItem>({});

  logoUrl = computed<string>(() => {
    const user = this.userService.account();
    if (user === null) {
      return '';
    }
    return user.logoUrl;
  });

  userName = computed<string>(() => {
    const user = this.userService.account();
    if (user === null) {
      return '';
    }
    return user.fullName;
  });

  copyrightYear = signal(this.getCurrentYear());

  constructor() {
    /** Menu items available to the user's profile once they are logged into the system */
    //this.loggedInItems = [
    //  {
    //    label: 'Logout Redirect',
    //    icon: 'pi pi-sign-out',
    //    command: () => {
    //      this.logout(false);
    //    }
    //  },
    //  {
    //    label: 'Logout Popup',
    //    icon: 'pi pi-sign-out',
    //    command: () => {
    //      this.logout(true);
    //    }
    //  }
    //];

    /** Menu items available to the user's profile icon BEFORE they are logged into the system */
    //this.loggedOutItems = [
    //  {
    //    label: 'Login Redirect',
    //    icon: 'pi pi-sign-in',
    //    command: () => {
    //      this.loginRedirect();
    //    }
    //  },
    //  {
    //    label: 'Login Popup',
    //    icon: 'pi pi-sign-in',
    //    command: () => {
    //      this.loginPopup();
    //    }
    //  }
    //];

    this.userMenuItems = [
      {
        label: 'View Profile',
        icon: 'fa-duotone fa-solid fa-user-circle',
        command: () => {
          console.log('View Profile');
        }
      },
      {
        label: 'Logout',
        icon: 'fa-duotone fa-solid fa-cog',
        command: () => {
          this.userService.LogOutUser();
        }
      }
    ];

    this.tenantService.tenant.pipe(takeUntilDestroyed()).subscribe({
      next: (tenant) => {
        this.menuItems.set(this.generateMenuItems(tenant.path));
      }
    });
  }

  ngOnInit(): void {
    if (this.userService.account() === null) {
      this.router.navigate(['/']);
    }
    //this.authService.handleRedirectObservable().subscribe();
    //
    //this.checkAndSetActiveAccount();
    //
    //this.authService.instance.enableAccountStorageEvents(); // Optional - This will enable ACCOUNT_ADDED and ACCOUNT_REMOVED events emitted when a user logs in or out of another tab or window
    //this.msalBroadcastService.msalSubject$
    //  .pipe(
    //    filter(
    //      (msg: EventMessage) =>
    //        msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED
    //    )
    //  )
    //  .subscribe({
    //    next: () => {
    //      if (this.authService.instance.getAllAccounts().length === 0) {
    //        window.location.pathname = '/';
    //      } else {
    //        this.checkAndSetActiveAccount();
    //      }
    //    }
    //  });
    //
    //this.msalBroadcastService.inProgress$
    //  .pipe(
    //    filter((status: InteractionStatus) => status === InteractionStatus.None),
    //    takeUntil(this._destroying$)
    //  )
    //  .subscribe(() => {
    //    this.checkAndSetActiveAccount();
    //  });
  }

  getCurrentYear() {
    return new Date().getFullYear();
  }

  generateMenuItems(rootPath: string): MenuItem[] {
    if (rootPath !== '') {
      if (rootPath.toLowerCase() === '/aspire') {
        return [
          {
            label: 'Global Visibility',
            icon: 'fa-duotone fa-solid fa-display-chart-up',
            command: () => {
              this.router.navigate([`${rootPath}/global`]);
            }
          },
          {
            label: 'Facility Visibility',
            icon: 'fa-duotone fa-solid fa-house-medical',
            command: () => {
              this.router.navigate([`${rootPath}/facility`]);
            }
          }
        ];
      }


      return [
        {
          label: 'Global Dashboard',
          icon: 'fa-duotone fa-solid fa-grid-2-plus',
          command: () => {
            this.router.navigate([`${rootPath}/dashboard`]);
          }
        },
        {
          label: 'Global Visibility',
          icon: 'fa-duotone fa-solid fa-display-chart-up',
          command: () => {
            this.router.navigate([`${rootPath}/global-tracking`]);
          }
        },
        {
          label: 'Facility Visibility',
          icon: 'fa-duotone fa-solid fa-house-medical',
          command: () => {
            this.router.navigate([`${rootPath}/facility-tracking`]);
          }
        },
        {
          label: 'Drugs Dispensed',
          icon: 'fa-duotone fa-solid fa-bed-pulse',
          command: () => {
            this.router.navigate([`${rootPath}/dispensed-drug-tracking`]);
          }
        },
        {
          label: 'Drugs Destroyed',
          icon: 'fa-duotone fa-solid fa-prescription-bottle-pill',
          command: () => {
            this.router.navigate([`${rootPath}/destroyed-drug-tracking`]);
          }
        }
      ];
    }
    return [];
  }

  //checkAndSetActiveAccount() {
  //  const allAccounts = this.authService.instance.getAllAccounts();
  //  if (allAccounts.length === 0) {
  //    this.activeAccount.set(null);
  //  }
  //
  //  /**
  //   * If no active account set but there are accounts signed in, sets first account to active account
  //   * To use active account set here, subscribe to inProgress$ first in your component
  //   * Note: Basic usage demonstrated. Your app may require more complicated account selection logic
  //   */
  //  let activeAccount = this.authService.instance.getActiveAccount();
  //
  //  if (activeAccount) {
  //    console.info(`active account = ${JSON.stringify(activeAccount)}`);
  //  }
  //
  //  if (!activeAccount && this.authService.instance.getAllAccounts().length > 0) {
  //    console.info(`active account IS NULL but we DO have accounts`);
  //    const accounts = this.authService.instance.getAllAccounts();
  //
  //    console.info(`account count = ${accounts.length}`);
  //
  //    this.authService.instance.setActiveAccount(accounts[0]);
  //    activeAccount = accounts[0];
  //  }
  //
  //  this.activeAccount.set(activeAccount);
  //  if (activeAccount !== null) {
  //    this.getUserProfileImage();
  //
  //    const items: MenuItem[] = [
  //      {
  //        label: 'Pharmacy',
  //        styleClass: 'headerMenuItems',
  //        items: [
  //          {
  //            label: 'Level 1',
  //            route: '/pharmacy',
  //            expanded: this.checkActiveState('/pharmacy')
  //          },
  //          {
  //            label: 'Level 2',
  //            route: '/pharmacy2',
  //            expanded: this.checkActiveState('/pharmacy2')
  //          }
  //        ]
  //      }
  //    ];
  //
  //    console.info(`Setting menu items`);
  //    this.appMenuItems.set(items);
  //  }
  //}

  checkActiveState(givenLink: string) {
    return this.router.url.toLowerCase().indexOf(givenLink.toLowerCase()) > -1;
  }

  closeSidebar(e: Event) {
    this.sidebarRef.close(e);
  }

  getUserProfileImage() {
    this.http
      .get(environment.graphApiConfig.root + environment.graphApiConfig.profilePhoto, { responseType: 'blob' })
      .subscribe({
        next: (value) => {
          if (value) {
            const reader = new FileReader();
            reader.addEventListener(
              'load',
              () => {
                this.profileImage.set(reader.result as string);
              },
              true
            );

            reader.readAsDataURL(value);
          }
        },
        error: () => {
          this.profileImage.set('');
        }
      });
  }

  //loginRedirect() {
  //  if (this.msalGuardConfig.authRequest) {
  //    this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
  //  } else {
  //    this.authService.loginRedirect();
  //  }
  //}
  //
  //loginPopup() {
  //  if (this.msalGuardConfig.authRequest) {
  //    this.authService
  //      .loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
  //      .subscribe((response: AuthenticationResult) => {
  //        this.authService.instance.setActiveAccount(response.account);
  //      });
  //  } else {
  //    this.authService.loginPopup().subscribe((response: AuthenticationResult) => {
  //      this.authService.instance.setActiveAccount(response.account);
  //    });
  //  }
  //}
  //
  //logout(popup?: boolean) {
  //  if (popup) {
  //    this.authService.logoutPopup({
  //      mainWindowRedirectUri: '/logout'
  //    });
  //  } else {
  //    this.authService.logoutRedirect();
  //  }
  //}

  menuClick(item: MenuItem) {
    console.log(item);
    this.currMenuItem.set(item);
    item.command!({});
  }

  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
