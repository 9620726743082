import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationStart, Router, RouterOutlet } from '@angular/router';
import { BlankTenant, tenantPaths } from '@const/tenants';
import { TenantHelperService } from '@services/tenant-helper/tenant-helper.service';

import { PrimeNGConfig } from 'primeng/api';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent {
  private primengConfig = inject(PrimeNGConfig);

  private router = inject(Router);

  private tenantService = inject(TenantHelperService);

  constructor(){
    this.primengConfig.ripple = true;

    this.router.events.pipe(takeUntilDestroyed()).subscribe({
      next: (event) => {
        if (event instanceof NavigationStart) {
          console.info('nav start');
          const lowerURL = event.url.toLowerCase();
          const matches = tenantPaths.filter((x) => lowerURL.startsWith(x.path));

          if (matches.length === 0) {
            console.info('setting blank tenant');
            this.tenantService.setTenant(BlankTenant);
          } else {
            console.info('tenant', matches[0]);
            this.tenantService.setTenant(matches[0]);
          }
        }
      }
    });
  }
}
