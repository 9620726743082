import { inject, Injectable } from '@angular/core';

import { environment } from '@env/environment';

import { HttpHelperService } from '@services/http-helper/http-helper.service';

import { AspireRecord } from '@models/aspireRecord';
import { map } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AspireDataService {
  private apiUrl = `${environment.apiUrl}v1/Aspire/`;

  private httpHelpers = inject(HttpHelperService);

  private addDays(date: Date, days: number) {
      let newDate = new Date(date);
      return new Date(newDate.setDate(newDate.getDate() + 30));
  }

  public GlobalRecords() {
    const now = new Date();
    const nowPlus14 = this.addDays(now, 14);

    return this.httpHelpers.get<AspireRecord[]>(`${this.apiUrl}Global`).pipe(map((data) => {
      data.map((x) => {
        if (x.expS && now > x.expS) {
          x.status = 'Expired';
        }
        else if (x.expS && x.expS < nowPlus14) {
          x.status = 'Expiration Risk';
        }
        else if (x.oh === 0) {
          x.status = 'No Stock';
        }
        else if (x.oh < x.min) {
          x.status = 'Restock';
        }
        else {
          x.status = 'In Stock';
        }
        //x.extendedCost = x.cost * x.
      });
      return data;
    }));
  }
}
