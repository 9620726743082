import { computed, inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@env/environment';
import { HttpHelperService } from '@services/http-helper/http-helper.service';
import { PulseUser } from './models/PulseUser';
import { tap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthHelperService {

  /** Local storage key for user data */
  private user_profile_key = 'user_profile';

  /** The default time a session stays valid (2 hours as per 2024.07.01) */
  private defaultSessionLength = 1000 * 60 * 120;

  private apiUrl = `${environment.apiUrl}v1/Auth/`;

  private httpHelpers = inject(HttpHelperService);

  private router = inject(Router);

  /** The currently logged in user. This is a computed field to prevent other classes from
   * setting it directly.
   */
  public account = computed(() => this.internalAccount());

  /** The currently logged in user. */
  private internalAccount = signal<PulseUser | null>(null);

  constructor() {
    if (!this.LoadUserFromSession()) {
      this.router.navigate(['/login']);
    }
    else {
      this.RedirectUserToHomePage();
    }
  }

  /**
   * Generates an instantaneous DTS for UTC
   * @returns The UTC version of a new Date()
   */
  private UTCNow = () => {
    // Set the start date for this session
    const now = new Date();
    return new Date(now.getUTCFullYear(), now.getUTCMonth(), now.getUTCDate(), now.getUTCHours(), now.getUTCMinutes(), now.getUTCSeconds());
  };

  public AttemptLogin(userName: string, password: string) {
    window.sessionStorage.clear();
    return this.httpHelpers.post<PulseUser>(`${this.apiUrl}login/${userName}`, { password }).pipe(tap({
      next: (user) => {
        // Set the start date for this session
        user.sessionStart = this.UTCNow().getTime();
        this.SetUserAccount(user);
      }
    }));
  }

  private SetUserAccount(user: PulseUser | null) {
    if (user !== null) {
      this.internalAccount.set(user);

      // Save user details to session storage
      localStorage.setItem(this.user_profile_key, JSON.stringify(user));
    }
    else {

      // Clear out local and session storage
      localStorage.removeItem(this.user_profile_key);
      this.router.navigate(['/']);
    }
  }

  public RedirectUserToHomePage() {
    const user = this.account();
    if (user) {
      this.router.navigate([user.redirectUrl]);
    }
  }

  public LogOutUser() {
    this.SetUserAccount(null);
  }


  /** Attempts to load user information from session variables  */
  public LoadUserFromSession(): boolean {
    try {
      // Try to load the user from session storage first
      const user = localStorage.getItem(this.user_profile_key);

      if (user) {
        const userObj = JSON.parse(user);
        if (this._logoutUserIfSessionExpired(userObj)) {
          return false;
        }

        this.SetUserAccount(userObj);
        return true;
      }

      return false;
    } catch (ex) {
      console.error(ex);
      return false;
    }
  }

  private _logoutUserIfSessionExpired(user: PulseUser) {
    // Log out the user immediately if they don't have a session start time
    if (!user.sessionStart) {
      this.LogOutUser();
      return true;
    }

    // Check to see if the session has timed out
    // 1) Get the user's session start date
    // 2) Add X (120) minutes to it (this matches PULSE 1.0's session logout time)

    const logoutTime = user.sessionStart! + this.defaultSessionLength;

    // 3) If the logout date is BEFORE the current time then log out the user
    if (logoutTime < this.UTCNow().getTime()) {
      this.LogOutUser();
      return true;
    }

    return false;
  }
}
