import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterOutlet } from '@angular/router';

@Component({
  standalone: true,
  selector: 'pulse-master-page-blank',
  imports: [RouterOutlet],
  templateUrl: './master-page-blank.component.html',
  styleUrl: './master-page-blank.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MasterPageBlankComponent {}
