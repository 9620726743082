import { ChangeDetectionStrategy, Component, inject, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';

import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MessageModule } from 'primeng/message';

import { AuthHelperService } from '../../services/auth-helper/auth-helper.service';

@Component({
  standalone: true,
  selector: 'pulse-login-page',
  imports: [ButtonModule, CheckboxModule, FormsModule, IconFieldModule, InputIconModule, InputTextModule, ReactiveFormsModule, MessageModule],
  templateUrl: './login-page.component.html',
  styleUrl: './login-page.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoginPageComponent {
  loginForm = new FormGroup({
    userName: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
    rememberMe: new FormControl(false)
  });

  /** Functions related to logged in users */
  private userService = inject(AuthHelperService);

  canLogIn = signal(false);

  /** Is the user currently attempting to log in? */
  attemptingLogin = signal(false);

  messages = signal<{ severity: string; content: string }[]>([]);

  constructor() {
    // If the user details has been saved to local storage then we want to use that account
    //if (this.userService && this.userService.LoadUserFromSession()) {
    //  this.router.navigate(['/']);
    //} else {
    //  this.currentRoute.paramMap.pipe(takeUntilDestroyed()).subscribe({
    //    next: (params) => {
    //      if (params) {
    //        if (params.keys.some((x) => x.toLowerCase() === 'loggedout')) {
    //          this.messages.set([{ severity: 'info', content: 'You have been logged out.' }]);
    //        }
    //      }
    //    }
    //  });

      // Listen to form data to see if we should enable the login button or not
      this.loginForm.valueChanges.pipe(takeUntilDestroyed()).subscribe({
        next: () => {
          const userName = this.loginForm.controls.userName.getRawValue()?.trim() ?? '';
          const password = this.loginForm.controls.password.getRawValue()?.trim() ?? '';
          this.canLogIn.set(userName.length > 0 && password.length > 0);
        }
      });
    //}
  }

  /**
   * Attempts to log the user into PULSE. If the login attempt is successful then the
   * user will be redirected to their home page. If the attempt fails, the user will get
   * a warning message and they can try again.
   */
  logInUser() {
    this.attemptingLogin.set(true);

    const userName = this.loginForm.controls.userName.value ?? '';
    const password = this.loginForm.controls.password.value ?? '';

    this.userService.AttemptLogin(userName, password).subscribe({
      next: () => {
        this.userService.RedirectUserToHomePage();
      },
      error: (err) => {
        console.info(err);
        this.messages.set([{ severity: 'error', content: 'Invalid username or password. Please try again.' }]);
        this.attemptingLogin.set(false);
      }
    });
  }
}
