import { ApplicationConfig, provideExperimentalZonelessChangeDetection } from '@angular/core';
import {
  //HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi
} from '@angular/common/http';
import {
  provideRouter //,
  //withComponentInputBinding,
  //withDisabledInitialNavigation,
  //withEnabledBlockingInitialNavigation
} from '@angular/router';
import { provideNoopAnimations } from '@angular/platform-browser/animations';

//import {
//  BrowserCacheLocation,
//  BrowserUtils,
//  IPublicClientApplication,
//  InteractionType,
//  LogLevel,
//  PublicClientApplication
//} from '@azure/msal-browser';
//import {
//  MSAL_GUARD_CONFIG,
//  MSAL_INSTANCE,
//  MSAL_INTERCEPTOR_CONFIG,
//  MsalBroadcastService,
//  MsalGuard,
//  MsalGuardConfiguration,
//  MsalInterceptor,
//  MsalInterceptorConfiguration,
//  MsalService
//} from '@azure/msal-angular';

import { routes } from './app.routes';
import { iso8601ToJsonDateHttpInterceptor } from './httpInterceptors/iso8601ToJsonDateHttpInterceptor';

//import { environment } from '@env/environment';

import { ConfirmationService, MessageService } from 'primeng/api';
import { TenantHelperService } from '@services/tenant-helper/tenant-helper.service';
import { AuthHelperService } from '@services/auth-helper/auth-helper.service';

//const initialNavigation =
//  !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
//    ? withEnabledBlockingInitialNavigation() // Set to enabledBlocking to use Angular Universal
//    : withDisabledInitialNavigation();
//
//// eslint-disable-next-line @typescript-eslint/no-unused-vars
//export function loggerCallback(logLevel: LogLevel, message: string) {
//  //console.log(message);
//}

//export function MSALInstanceFactory(): IPublicClientApplication {
//  return new PublicClientApplication({
//    auth: {
//      clientId: environment.msalConfig.auth.clientId,
//      authority: environment.msalConfig.auth.authority,
//      redirectUri: environment.msalConfig.auth.redirectUri,
//      postLogoutRedirectUri: environment.msalConfig.auth.postLogOutUri
//    },
//    cache: {
//      cacheLocation: BrowserCacheLocation.SessionStorage
//    },
//    system: {
//      allowNativeBroker: false, // Disables WAM Broker
//      loggerOptions: {
//        loggerCallback,
//        logLevel: LogLevel.Verbose,
//        piiLoggingEnabled: false
//      }
//    }
//  });
//}
//
//export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
//  const protectedResourceMap = new Map<string, Array<string>>();
//  protectedResourceMap.set(environment.graphApiConfig.root, environment.graphApiConfig.scopes);
//
//  environment.msalProtectedEndpoints.forEach((endpoint: { scopes: string[]; root: string }) => {
//    protectedResourceMap.set(endpoint.root, endpoint.scopes);
//  });
//
//  protectedResourceMap.forEach((v, k) => {
//    console.info(`key: ${k}: Values: '${JSON.stringify(v)}'`);
//  });
//
//  return {
//    interactionType: InteractionType.Redirect,
//    protectedResourceMap
//  };
//}
//
//export function MSALGuardConfigFactory(): MsalGuardConfiguration {
//  return {
//    interactionType: InteractionType.Redirect,
//    authRequest: {
//      scopes: [...environment.graphApiConfig.scopes]
//    },
//    loginFailedRoute: '/login-failed'
//  };
//}

export const appConfig: ApplicationConfig = {
  providers: [
    provideExperimentalZonelessChangeDetection(),
    //provideRouter(routes, initialNavigation, withComponentInputBinding()),
    provideRouter(routes),
    provideHttpClient(withFetch(), withInterceptors([iso8601ToJsonDateHttpInterceptor()]), withInterceptorsFromDi()),
    //{
    //  provide: HTTP_INTERCEPTORS,
    //  useClass: MsalInterceptor,
    //  multi: true
    //},
    //{
    //  provide: MSAL_INSTANCE,
    //  useFactory: MSALInstanceFactory
    //},
    //{
    //  provide: MSAL_GUARD_CONFIG,
    //  useFactory: MSALGuardConfigFactory
    //},
    //{
    //  provide: MSAL_INTERCEPTOR_CONFIG,
    //  useFactory: MSALInterceptorConfigFactory
    //},
    //MsalService,
    //MsalGuard,
    //MsalBroadcastService,
    provideNoopAnimations(),
    ConfirmationService,
    MessageService,
    TenantHelperService,
    AuthHelperService
  ]
};
