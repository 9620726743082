<div class="flex flex-col place-content-center place-items-center h-full" style="margin-top: -80px; gap: 5px">
  <img src="/assets/lumatrak_pulse_no_icon.png" alt="Login to Pulse" style="width: 200px; margin-bottom: 10px;" />
  <div class="card" style="width:350px">
    <div class="card-body" style="padding-top: 10px; padding-bottom: 10px; padding-left: 4px; padding-right: 4px;">
      <div style="margin-bottom: 10px;">
        <h3 class="font-bold text-center" style="margin-bottom: 2px;">Sign In</h3>
        <p class="text-center text-surface-500">Welcome back! Please sign in to your account.</p>
      </div>

      <div style="margin-bottom: 5px">
        @for (message of messages(); track message) {
          <p-message [severity]="message.severity" [text]="message.content"></p-message>
        }
      </div>

      <form class="flex flex-col" [formGroup]="loginForm">
        <div class="flex flex-col">
          <label for="username" class="font-medium mb-2">Username</label>
          <input formControlName="userName" id="username" placeholder="Enter your username" type="text" data-lpignore="true" pInputText class="w-full" />
        </div>

        <div class="flex flex-col" style="margin-top: 10px; margin-bottom: 10px;">
          <label for="password1" class="font-medium mb-2">Password</label>
            <input
              formControlName="password"
              id="password1"
              data-lpignore="true"
              type="password"
              placeholder="Enter your password"
              pInputText
              class="w-full"
              autocomplete="password" />
        </div>

        <p-button
          styleClass="w-full"
          label="Log In"
          class="LoginButton"
          type="Submit"
          (onClick)="logInUser()"
          [disabled]="!canLogIn()"
          [loading]="attemptingLogin()"></p-button>
      </form>
    </div>
  </div>
</div>
